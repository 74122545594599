import React, { Component } from "react";
import Dashboard from "./dashboard/Dashboard";
import Confirmation from "./create/Confirmation";
import Today from "./date/Today";
import Floor from "./Floor";
import History from "./History";
import CreateNew from "./create/CreateNew";
import { Route, Link, Switch } from "react-router-dom";
import { NavBar } from "./NavBar";
import Ops from "./Ops";
import { AppContext } from './context/AppContext';
import { Helmet } from 'react-helmet'

export default class Outlet extends Component {
  static contextType = AppContext;
  render() {
    const { outlet } = this.context;
    return (
      <div className="outlet">
        <Helmet>
          <title>{this.context.outlet}</title>
        </Helmet>
        {outlet === "TCS" ? <img src={require(`../images/${outlet}.png`)}></img> : <img id="inverse-logo" src={require(`../images/${outlet}.png`)}></img>}
        <span id="version">2.0</span>
        <NavBar></NavBar>
        <Route
          path={`/main/dashboard`}
          render={(props) => (
            <Dashboard
              {...props}
              setCount={() => this.setCount}

            />
          )}
        />
        <Route
          path={`/main/edit/:reserve`}
          render={(props) => (
            <CreateNew {...props} />
          )}
        />
        <Route
          path={`/main/today`}
          render={(props) => <Today {...props} />}
        />
        <Route
          path={`/main/history`}
          render={(props) => <History {...props} />}
        />
        <Route
          path={`/main/floor`}
          render={(props) => <Floor {...props} />}
        />
        <Route
          path={`/main/ops`}
          render={(props) => <Ops {...props} />}
        />

        <Route
          path={`/main/confirmation`}
          render={(props) => (
            <Confirmation {...props} />
          )}
        />
      </div>
    );
  }
}
