import React, { Component, createContext } from "react";
export const AppContext = createContext();

export default class AppContextProvider extends Component {
  state = {
    unaddressed: 0,
    unaddressedToday: 0,
    outlet: "TCS",
    view: true,
  };

  setCount = (unaddressed) => {
    this.setState({ unaddressed });
  };
  setCountToday = (unaddressedToday) => {
    this.setState({ unaddressedToday });
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          setCount: this.setCount,
          setCountToday: this.setCountToday
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
